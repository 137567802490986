<script>
import InputField from '../general/InputField'
import NewContentCard from './NewContentCard'
import EmptyList from '../general/EmptyList.vue'
import Breadcrumbs from '@/components/general/Breadcrumbs'
import Loading from '../general/Loading.vue'
export default {
  name: 'NewContentsList',
  components: {
    InputField,
    NewContentCard,
    EmptyList,
    Breadcrumbs,
    Loading
  },
  data () {
    return {
      searchTerm: '',
      favoriteCourses: [],
      isLoading: true
    }
  },
  methods: {
    btnClick () {
      this.$router.push({ name: this.selectedWorkspace.type + '.discovery.index.search', query: { companyDomain: this.selectedWorkspace.name, type: 'courses' } })
    }
  },
  computed: {
    filteredCourses () {
      return this.favoriteCourses.filter((course) => course.title.toLowerCase().includes(this.searchTerm.toLowerCase()))
    },
    breadcrumbsPaths () {
      const path = [{ text: 'main.header:contents', href: this.$route.path }]
      this.isSearching && path({ text: 'global:search', href: this.$route.path })
      return path
    },
    showList () {
      return this.$route.meta.hideList
    }
  },
  created () {
    Promise.all([
      this.$store.dispatch('attemptGetFavoriteCourses', { aggregator: this.getCompanyDomainAndWorkspace() })
    ]).then(data => {
      this.favoriteCourses = data[0]
      this.isLoading = false
    })
  }
}
</script>
<template>
  <section class="contents-list--container">
    <template v-if="showList">
      <div class="default-header">
        <breadcrumbs :items="breadcrumbsPaths" />
        <h2>{{ $t('contents.list.page:title') }}</h2>
      </div>
      <div class="default-content">
        <div class="contents-list--filter-wrapper">
          <div class="contents-list--tip-wrapper">
            <h5 class="contents-list--tip-title">{{ $t('contents.list.page:tip.title') }}</h5>
            <p class="contents-list--tip-description">{{ $t('contents.list.page:tip.description') }}</p>
          </div>
          <input-field v-if="favoriteCourses.length" v-model="searchTerm" outlined small append-icon="mdi-magnify"
            :placeholder="$t('global:search.alt')" background-color="#fff"></input-field>
        </div>

        <Loading class="loading-candidature--wrapper" v-if="isLoading"/>
        <div v-else>
          <div class="contents-list--list-wrapper" v-if="favoriteCourses.length">
            <new-content-card v-for="(favoriteCourse) in filteredCourses" :key="favoriteCourse.id" :course="favoriteCourse"></new-content-card>
          </div>
          <empty-list @btnClick='btnClick' v-else :title="$t('empty.state.title.favorites:empty.title')"
            :textOne="$t('empty.state.text:empty.text')" :image="'/assets/images/empty-box.png'"
            :buttonText="$t('empty.state.button:discovery')"
            :validateRole="true"
            ></empty-list>
        </div>
      </div>
    </template>
    <router-view></router-view>
  </section>
</template>
<style lang="scss">
.contents-list--container {

  .loading-candidature--wrapper {
    height: 50vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .contents-list--filter-wrapper {
    display: flex;
    justify-content: space-between;

    .contents-list--tip-wrapper {
      text-align: left;

      h5.contents-list--tip-title {
        font-family: $lato;
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 24px;
        color: #242321;
        margin-bottom: 8px;
      }

      p.contents-list--tip-description {
        font-family: 'Lato';
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 20px;
        color: #7E7D77;
      }
    }
  }

  .contents-list--list-wrapper {
    display: grid;
    grid-gap: 16px;
    grid-template-columns: repeat(auto-fill, minmax(296px, 1fr));

    &>div {
      width: 100%;
    }

    .content-card--container {
      width: 100%;
      margin: 0;
    }
  }
}
</style>
